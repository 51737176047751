<template lang="html">
  <error-page-layout
    title="Sua sessão expirou"
    first-message="Você ficou inativo por muito tempo e foi desconectado por motivos de segurança."
    image-name="expired.png"
  >
    <v-btn slot="buttons" class="px-0" color="orange" text @click="redirect">Fazer login novamente</v-btn>
  </error-page-layout>
</template>

<script>
import { formattedRedirectLogout } from '@/helpers/login'
import ErrorPageLayout from '@/modules/errors/components/ErrorPageLayout.vue'

export default {
  components: {
    ErrorPageLayout
  },
  methods: {
    async redirect() {
      const singleLoginURL = this.$microservicesUrls['mfLogin']
      const host = location.href.slice(0, location.href.indexOf('#'))
      const hash = window.localStorage.getItem('redirectAfterLogin') || ''

      this.$logout()
      window.location.href = formattedRedirectLogout(singleLoginURL, host, hash)
    }
  }
}
</script>
